import React from "react"
import Single from "../components/single"

export default () => (
  <Single
    slug="404"
    title="404 Page Not Found"
    description="404 Page Not Found. 申し訳ありませんが、お探しのページはございません"
  >
    <h2>404</h2>
    <h1>Page Not Found</h1>
    <p>
      The specified file was not found on this website. Please check the URL for
      mistakes and try again.
    </p>
  </Single>
)
